exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-event-thank-you-tsx": () => import("./../../../src/pages/event-thank-you.tsx" /* webpackChunkName: "component---src-pages-event-thank-you-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-gallery-past-events-tsx": () => import("./../../../src/pages/gallery/past-events.tsx" /* webpackChunkName: "component---src-pages-gallery-past-events-tsx" */),
  "component---src-pages-gallery-photos-and-videos-tsx": () => import("./../../../src/pages/gallery/photos-and-videos.tsx" /* webpackChunkName: "component---src-pages-gallery-photos-and-videos-tsx" */),
  "component---src-pages-gallery-photos-tsx": () => import("./../../../src/pages/gallery/photos.tsx" /* webpackChunkName: "component---src-pages-gallery-photos-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-gallery-upcoming-events-tsx": () => import("./../../../src/pages/gallery/upcoming-events.tsx" /* webpackChunkName: "component---src-pages-gallery-upcoming-events-tsx" */),
  "component---src-pages-gallery-videos-tsx": () => import("./../../../src/pages/gallery/videos.tsx" /* webpackChunkName: "component---src-pages-gallery-videos-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jake-paul-vs-mike-tyson-over-108-million-live-global-viewers-tsx": () => import("./../../../src/pages/jake-paul-vs-mike-tyson-over-108-million-live-global-viewers.tsx" /* webpackChunkName: "component---src-pages-jake-paul-vs-mike-tyson-over-108-million-live-global-viewers-tsx" */),
  "component---src-pages-mvp-athletes-tsx": () => import("./../../../src/pages/mvp-athletes.tsx" /* webpackChunkName: "component---src-pages-mvp-athletes-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-paul-vs-perry-fear-no-man-tsx": () => import("./../../../src/pages/paul-vs-perry-fear-no-man.tsx" /* webpackChunkName: "component---src-pages-paul-vs-perry-fear-no-man-tsx" */),
  "component---src-pages-paulvstyson-official-statement-tsx": () => import("./../../../src/pages/paulvstyson-official-statement.tsx" /* webpackChunkName: "component---src-pages-paulvstyson-official-statement-tsx" */),
  "component---src-pages-paulvstyson-rescheduled-tsx": () => import("./../../../src/pages/paulvstyson-rescheduled.tsx" /* webpackChunkName: "component---src-pages-paulvstyson-rescheduled-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-prospects-tsx": () => import("./../../../src/pages/prospects.tsx" /* webpackChunkName: "component---src-pages-prospects-tsx" */),
  "component---src-pages-statement-from-most-valuable-promotions-regarding-jake-paul-and-canelo-alvarez-tsx": () => import("./../../../src/pages/statement-from-most-valuable-promotions-regarding-jake-paul-and-canelo-alvarez.tsx" /* webpackChunkName: "component---src-pages-statement-from-most-valuable-promotions-regarding-jake-paul-and-canelo-alvarez-tsx" */),
  "component---src-pages-taylorserrano-tsx": () => import("./../../../src/pages/taylorserrano.tsx" /* webpackChunkName: "component---src-pages-taylorserrano-tsx" */),
  "component---src-pages-tempxyz-event-form-tsx": () => import("./../../../src/pages/tempxyz/event-form.tsx" /* webpackChunkName: "component---src-pages-tempxyz-event-form-tsx" */),
  "component---src-pages-tempxyz-paulvstyson-tsx": () => import("./../../../src/pages/tempxyz/paulvstyson.tsx" /* webpackChunkName: "component---src-pages-tempxyz-paulvstyson-tsx" */),
  "component---src-pages-terms-and-conditions-giveaway-tsx": () => import("./../../../src/pages/terms-and-conditions-giveaway.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-giveaway-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-templates-event-upcoming-details-tsx": () => import("./../../../src/templates/event-upcoming-details.tsx" /* webpackChunkName: "component---src-templates-event-upcoming-details-tsx" */),
  "component---src-templates-fighter-profile-tsx": () => import("./../../../src/templates/fighter-profile.tsx" /* webpackChunkName: "component---src-templates-fighter-profile-tsx" */),
  "component---src-templates-past-events-details-tsx": () => import("./../../../src/templates/past-events-details.tsx" /* webpackChunkName: "component---src-templates-past-events-details-tsx" */),
  "component---src-templates-press-article-tsx": () => import("./../../../src/templates/press-article.tsx" /* webpackChunkName: "component---src-templates-press-article-tsx" */)
}

